<template>
  <b-card>
    <FormBuilder
      label="Game"
      update
      :data="$store.getters['Game/detailData']"
      :fields="fields"
      :loading-detail="$store.state.Game.loadingDetail"
      :loading-submit="$store.state.Game.loadingSubmit"
      :diff-only="false"
      @load="loadData"
      @submit="submitData"
      @reset="resetData"
    />
  </b-card>
</template>

<script>
import FormBuilder from '@/layouts/components/FormBuilder.vue'

import {
  BCard,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    FormBuilder,
  },
  data() {
    return {
      // Fields
      fields: [
        {
          key: 'name',
          label: 'Name',
          required: true,
        },
        {
          key: 'description',
          label: 'Description',
          type: 'textarea',
          minLength: 5,
        },
        {
          key: 'platform',
          label: 'Platform',
          required: true,
          type: 'multiple',
          options: [
            { value: 'pc', text: 'PC' },
            { value: 'mobile', text: 'MOBILE' },
            { value: 'console', text: 'CONSOLE' },
          ],
        },
        {
          key: 'image',
          label: 'Image',
          type: 'image',
          required: true,
        },
        {
          key: 'icon',
          label: 'Icon',
          type: 'image',
          required: true,
        },
        {
          key: 'sort',
          label: 'Sort',
          prefix: '#',
          type: 'number',
        },
        {
          key: 'specialization',
          label: 'Game Specialization',
          type: 'multiple',
        },
      ],
    }
  },
  methods: {
    loadData(id) {
      return this.$store.dispatch('Game/getDetail', id)
    },
    submitData(id, data, callback) {
      this.$store.dispatch('Game/update', { id, data })
        .then(callback)
    },
    resetData() {
      return this.$store.commit('Game/SET_DETAIL_DATA', undefined)
    },
  },
}
</script>

    <style scoped>

    </style>
